// Colors
$color-primary: #0A467F;
$color-secondary: #0D5DA8;
$color-gray: #323232;
$color-medium-gray: #344251;
$color-light-gray: #F0EFEF;
$color-white: #FFFFFF;
$color-purple: #753972;
$color-error: #DC3545;

.card-header:focus {
  border: solid 2px rgba(13, 93, 168, 0.5);
}

a.logo:focus,
#navigation a:focus li,
.fa-bars:focus,
.skip-to-content:focus li {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important;
}

.form-checkbox .checkbox:focus,
.fa-times:focus,
.deal-card h5 a:focus,
p.field-remove:focus {
  outline: solid 2px rgba(13, 93, 168, 0.5) !important;
}

a.deal-action-item:focus {
  color: $color-primary;
  background-color: $color-white !important;
  box-shadow: 0 0 0 0.2rem rgba(13, 93, 168, 0.5) !important;
}

.form-control:not(.search-input):focus {
  background-color: $color-light-gray !important;
  border-bottom: solid 2px $color-light-gray !important;
  box-shadow: 0 0 0 0.2rem rgba(13, 93, 168, 0.5) !important;
}

.form-control.input-error:focus {
  background-color: $color-light-gray !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5) !important;
  border-bottom: solid 2px $color-error !important;
  position: relative;
  z-index: 2;
}

.btn-upload:focus {
  background-color: $color-light-gray !important;
  box-shadow: 0 0 0 0.2rem rgba(13, 93, 168, 0.5) !important;
}

a:focus,
.auth-form a.logo:focus,
.panel a:focus,
.btn.btn-primary:focus,
.btn.btn-secondary:focus,
.dropdown a:focus img,
.nav-tabs a:focus,
button.fake-link:focus,
button.inbox-action-item:focus,
.skip-to-messenger:focus,
.message-uploads .badge-pill:focus,
.my-id:focus,
.folder-name:focus,
.file-name:focus,
.folder-name + .dropdown:focus,
.file-name + .dropdown:focus,
.folder-file-name:focus,
.primary-photo-star:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 93, 168, 0.5) !important;
}

.grid-col-container.dark  {
  .btn-folder:focus {
    box-shadow: 0 0 0 0.2rem rgba(255,255,255, 1) !important;
  }

  .btn-file-upload:focus {
    box-shadow: 0 0 0 0.2rem rgba(255,255,255, 0.5) !important;
  }
}

#navigation a:focus {
  box-shadow: none !important;
}

.inbox-card:focus-within .inbox-actions {
  display: block !important;
}

.mobile-menu a:focus {
  outline: none !important;
  box-shadow: none !important;
}