$color-primary: #0a467f;
$color-light-gray: #f0efef;

.inbox-labels {
  padding-left: 24px;
  .inbox-label-header {
    color: $color-primary;
  }
  .inbox-label-add {
    align-items: center;
    margin-left: 45%;
    font-size: 20px;
  }
}
.message-card {
  display: flex;
  gap: 10px;
  padding: 12px 16px 12px 12px;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
  border-left: 3px solid transparent !important;

  &.Message {
    &.inbox {
      &.isNotReadByMe {
        &.isNotRespondedByOtherTeamMembers {
          background-color: lighten($color: $color-primary, $amount: 70);
          .message-title {
            font-weight: bold;
          }
        }
        &.isRespondedByOtherTeamMembers {
          background-color: transparent;
          .message-title {
            font-weight: bold;
          }
        }
      }
      &.isReadByMe {
        &.isNotRespondedByOtherTeamMembers {
          background-color: lighten($color: $color-primary, $amount: 70);
          .message-title {
            font-weight: normal;
          }
        }
        &.isRespondedByOtherTeamMembers {
          background-color: transparent;
          .message-title {
            font-weight: normal;
          }
        }
      }
    }
  }

  &.Question {
    &.inbox {
      &.isNotReadByMe {
        &.isNotRespondedByOtherTeamMembers {
          background-color: lighten($color: $color-primary, $amount: 70);
          .message-title {
            font-weight: bold;
          }
          &.isBorrower {
            background-color: transparent;

            &.hasReplies {
              background-color: lighten($color: $color-primary, $amount: 70);
              .message-title {
                font-weight: normal;
                .anticon-question-circle {
                  color: #6c757d;
                }
              }
            }
          }
        }
        &.isRespondedByOtherTeamMembers {
          background-color: transparent;
          .message-title {
            font-weight: bold;
          }
        }
      }
      &.isReadByMe {
        &.isNotRespondedByOtherTeamMembers {
          background-color: lighten($color: $color-primary, $amount: 70);
          .message-title {
            font-weight: normal;
            .anticon-question-circle {
              color: #6c757d;
            }
          }
        }
        &.isRespondedByOtherTeamMembers {
          background-color: transparent;
          .message-title {
            font-weight: normal;
            .anticon-question-circle {
              color: #6c757d;
            }
          }
        }
      }
    }
  }

  .message-avatar {
    display: flex;
    gap: 10px;
  }

  .message-wrapper {
    width: 100%;
  }

  .message-details {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .message-title {
    display: inline-flex;
    align-self: center;
  }

  .message-datetime {
    text-align: right;
  }

  .message-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.selected {
    border-left: 3px solid $color-primary !important;
    // background-color: $color-light-gray !important;
  }
}
.follow-up {
  .message-content,
  .message-content > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.inbox-bulk-actions {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
}

.InboxReplies--item {
  padding-top: 20px;
  padding-bottom: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid $color-light-gray;
  }
}

.inbox-label-node {
  .inbox-label-node-options {
    visibility: hidden;
  }
  &:hover {
    .inbox-label-node-options {
      visibility: visible;
    }
  }
}
