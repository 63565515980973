// Colors
$color-primary: #0a467f;
$color-secondary: #0d5da8;
$color-gray: #323232;
$color-medium-gray: #344251;
$color-light-gray: #f0efef;
$color-white: #ffffff;
$color-purple: #753972;
$color-purple-2: #841584;
$color-error: #dc3545;

// Fonts
h1 {
  font-size: 35px;
  line-height: 1;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

.font {
  &-10 {
    font-size: 10px;
  }

  &-12 {
    font-size: 12px;
  }

  &-14 {
    font-size: 14px;
  }

  &-16 {
    font-size: 16px;
  }

  &-18 {
    font-size: 18px;
  }

  &-20 {
    font-size: 20px;
  }
}

.color-medium-gray {
  color: $color-medium-gray;
  opacity: 0.75;
}

.color-white {
  color: $color-white;
}

.color-purple {
  color: $color-purple;
}

.color-purple-2 {
  color: $color-purple-2 !important;
}

.bold {
  font-weight: 700;
}

// Utility
.no-padding {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.pre-line {
  white-space: pre-line;
}
.gap-10 {
  gap: 10px;
}
.gap-16 {
  gap: 16px;
}
.gap-20 {
  gap: 20px;
}
.gap-30 {
  gap: 30px;
}
.gap-40 {
  gap: 40px;
}
.line-height-none {
  line-height: 1em;
}
.w-20 {
  width: 20%;
}
.sidebar-scrollbar {
  &::-webkit-scrollbar-track {
    background: rgba(229, 229, 229, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c4c4c4;
  }
}

// Forms
form {
  padding: 30px;
  border: solid #0d5da8 1px;

  &.search-form {
    position: relative;
    max-width: 53%;
    width: 100%;
    margin-right: 30px;
  }

  h6 {
    opacity: 0.75;
    color: $color-medium-gray;
  }

  input.form-control:not(.search-input),
  textarea.form-control {
    border: none;
    border-bottom: solid 2px $color-primary;
    border-radius: 0px;
    line-height: 1;
    padding: 0px;
    margin-bottom: 2px;

    &:focus {
      box-shadow: none;
      border-bottom: solid 2px $color-secondary;
    }

    &.input-error {
      border-bottom: solid 2px $color-error;

      &::placeholder {
        opacity: 0.75;
        color: $color-error;
      }
    }

    + span.error {
      color: $color-error;
      background-color: $color-white;
      position: absolute;
      display: block;
      width: 100%;
      z-index: 1;
    }
  }

  input.form-control.search-input {
    width: 100%;
    height: auto;
    border-radius: 0px;
    line-height: 1;
    padding-right: 66px;
  }

  .search-icon {
    color: $color-primary;
    z-index: 1;
    display: block !important;
    position: absolute;
    right: 0;
    top: 0;
    height: 33px;
    width: 33px !important;
    padding: 9px;
    box-sizing: border-box !important;

    &:focus {
      background-color: rgba(82, 88, 93, 0.1);
    }
  }

  .clear-icon {
    color: $color-primary;
    z-index: 1;
    display: block !important;
    position: absolute;
    right: 33px;
    top: 0;
    height: 33px;
    width: 33px !important;
    padding: 9px;
    box-sizing: border-box;

    &:focus {
      background-color: rgba(82, 88, 93, 0.1);
    }
  }

  .search-option-icon {
    width: 16px;
    height: 16px;
  }

  .search-results-wrapper {
    z-index: 99999;
    position: absolute;
    width: 100%;
    border: solid 1px #ced4da;
    border-top: none;
    background-color: $color-white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);

    p {
      text-align: left;
      display: block;
      width: 100%;
      padding: 5px 12px;
      margin: 5px 0px;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $color-secondary;

        svg,
        span {
          color: $color-white;
          opacity: 1;
        }
      }
    }

    svg {
      color: $color-primary;
      width: 18px !important;
    }
  }

  textarea.form-control {
    line-height: 22px;
  }

  .field-wrapper .input-error {
    border: solid 2px $color-error;
  }

  span.error {
    color: $color-error;
    background-color: $color-white;
    position: absolute;
    display: block;
    width: 100%;
    z-index: 1;
  }

  textarea.form-control,
  textarea.form-control:focus {
    border: solid 1px $color-primary;
    padding: 5px;

    &.input-error {
      border: solid 2px $color-error;
    }
  }

  &.auth-form {
    max-width: 705px;
    margin: 0 auto;
  }

  .checkbox {
    margin-bottom: 2px;

    h5 {
      opacity: 0.75;
    }

    label:not(.input-error) h5 {
      color: $color-medium-gray;
    }

    label.input-error h5 {
      color: $color-error;
    }

    p.error {
      color: $color-error;
      background-color: $color-white;
      position: absolute;
      display: block;
      width: 100%;
      z-index: 1;
    }
  }

  input[type="checkbox"] {
    z-index: 1;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0;

    & + label {
      position: absolute;
      left: 40px;
      top: 0px;
      z-index: 0;
      line-height: 20px;
      pointer-events: none;
    }
  }

  .field-wrapper {
    position: relative;

    .field-remove {
      color: $color-secondary;
      font-size: 14px;
      cursor: pointer;
      position: absolute;
      top: 27px;
      right: 5px;
    }

    &-no-spacer {
      input {
        padding-left: 8px;
      }

      .field-remove {
        color: $color-secondary;
        font-size: 14px;
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 20px;
      }
    }
  }

  .icon-input {
    position: relative;

    svg {
      z-index: 3;
      position: absolute;
      top: 10px;
    }

    input {
      padding-left: 20px !important;
    }
  }

  .form-checkbox {
    display: inline-block;
    padding-left: 0px;
    margin-right: 10px;
    cursor: pointer;

    input {
      display: none;
    }

    .checkbox-label {
      opacity: 0.75;
      margin-left: 20px;
    }

    .checkbox {
      width: 14px;
      height: 14px;
      border-radius: 50px;
      border: solid $color-gray 2px;
      position: absolute;
      left: 0px;
    }

    input:checked + .checkbox {
      background-color: $color-secondary;
    }
  }

  // Image Upload Field
  .img-upload {
    img,
    .profile-image-upload-icon-wrapper {
      display: block;
      margin: 0 auto;
      background-color: $color-white;
      border: solid 1px $color-primary;
      max-width: 265px;
      max-height: 265px;
      width: 100%;
      height: auto;

      &.input-error {
        border: solid 2px $color-error;
      }
    }

    .profile-image-upload-icon-wrapper {
      display: flex;
      width: 265px;
      height: 265px;
      justify-content: center;
      align-items: center;
      font-size: 60px;
    }

    .btn-upload-wrapper {
      position: relative;
      text-align: center;
      width: 55px;
      margin: 0 auto;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }

      p {
        color: $color-gray;
        width: 55px;
        height: 29px;
        line-height: 28px;
      }

      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 55px;
      }

      input[type="file"]::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }

  // React Select Field
  .css-yk16xz-control,
  .css-yk16xz-control:hover,
  .css-yk16xz-control:focus,
  .css-1pahdxg-control {
    border: solid 1px $color-primary;
    border-radius: 0;

    svg {
      color: $color-primary;
    }
  }

  .css-26l3qy-menu {
    z-index: 4;
  }

  .css-1wa3eu0-placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select-input-error {
    border: solid 2px $color-error;

    .css-yk16xz-control,
    .css-yk16xz-control:focus,
    .css-1pahdxg-control,
    .css-2b097c-container {
      border: none;
    }

    + span.error {
      color: $color-error;
      background-color: $color-white;
      position: absolute;
      display: block;
      width: 100%;
      z-index: 1;
    }

    &::placeholder {
      opacity: 0.75;
      color: $color-error;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-tlfecz-indicatorContainer svg {
    color: $color-primary;
  }

  &.full-width {
    max-width: 100%;
  }
}

.form-wizard-wrapper {
  margin: 0 auto;
  border: solid #0d5da8 1px;
  padding: 30px;
}

.dashboard-select-container {
  display: flex;
  align-items: center;

  p {
    display: inline-block;
  }

  .dashboard-select {
    margin-left: 10px;
    margin-bottom: 0px;

    select {
      border-radius: 0px;
    }
  }
}

// Buttons
.btn {
  border-radius: 0px;
  border: none;
  padding: 7px 45px;

  &[disabled] {
    cursor: not-allowed;
  }

  &.btn-primary {
    background-color: $color-primary;

    &:hover {
      background-color: $color-secondary;
    }

    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }

  &.btn-primary {
    &.outline {
      border: 1px solid $color-primary;
      background-color: $color-white;
      color: $color-primary;
      &:hover {
        border: 1px solid $color-secondary;
        background-color: $color-white;
        color: $color-secondary;
      }

      &:focus {
        box-shadow: none;
      }

      &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
      }
    }
  }

  &.btn-secondary {
    background-color: $color-secondary;

    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }

  &.btn-tertiary {
    color: $color-white;
    background-color: $color-purple;

    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }

  &.btn-paginate {
    background-color: $color-secondary;
    color: $color-white;
    border-radius: 50px;
    padding: 0px;
    width: 40px;
    height: 40px;

    &:hover {
      background-color: $color-primary;
    }
  }

  &.btn-inbox-action {
    background-color: none;
    border-radius: 50px;
    padding: 0px;
    width: 44px;
    height: 44px;

    svg {
      vertical-align: middle;
    }

    &:active {
      background-color: $color-light-gray;
    }
  }

  &.btn-folders {
    background-color: none;
    border: none;
    border-radius: 50px;

    svg {
      vertical-align: middle;
    }

    &:active {
      background-color: $color-light-gray;
    }
  }

  &.btn-stars {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    border: none;
    border-radius: 0px;
    padding: 0px;

    svg {
      vertical-align: middle;
    }
  }

  &.btn-grid-upload {
    width: 160px;
    height: 160px;
    text-align: center;
    cursor: pointer;
    border: 2px dashed $color-gray;
    display: inline-block;
    color: $color-gray;
  }

  &.btn-file-upload {
    display: inline-block;
    cursor: pointer;
    border: 2px solid $color-primary;
    padding: 2px 12px;
    color: $color-primary;
    z-index: 2;

    &:hover {
      color: $color-white;
      background-color: $color-primary;
    }

    &.dark {
      border: 2px solid $color-white;
      color: $color-white;

      &:hover {
        color: $color-primary;
        background-color: $color-white;
      }
    }
  }

  &.deal-checklist-item {
    position: relative;
    text-align: left;
    padding: 7px 20px;
    margin-bottom: 10px;

    svg {
      color: $color-medium-gray;
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }

  &.field-complete {
    background-color: $color-primary;
    color: $color-white;

    svg {
      color: $color-white;
    }
  }
  &.field-disabled {
    background-color: #cccccc !important;
  }

  &.full-width {
    padding: 7px 0;
    width: 100%;
  }
}

.btn-social {
  box-sizing: border-box;
  padding: 7px;
  width: 30px;
  height: 30px;
  line-height: 20px;

  &.d-block {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

.btn-upload {
  box-sizing: border-box;
  background-color: transparent;
  color: $color-primary;
}

button.fake-link.btn-fake-link {
  cursor: pointer;
  display: inline-block;
  color: #007bff;
  background: none;
  border: none;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  &[disabled] {
    cursor: not-allowed;
    text-decoration: none;
  }
}

// Root
body,
#root {
  color: $color-medium-gray;
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto", "sans-serif";
}

// Sidebar
aside#sidebar {
  display: none;
  color: white;
  background-color: $color-primary;
  height: 100%;
  overflow-y: scroll;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  svg.navbarIcon {
    font-size: 24px;
    box-sizing: border-box;
    cursor: pointer;
  }

  a {
    color: $color-white;
    text-decoration: none;
  }

  h4 {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 278px;
  transition: width 200ms ease-in-out;
}

.sidebar-col {
  display: none;
  flex: 0 0 278px;
  padding: 0;
  position: relative;
  min-height: 100vh;
  white-space: nowrap;
  transition: all 200ms ease-in-out;

  &.toggled {
    flex: 0 0 75px;

    .sidebar-wrapper {
      width: 75px;
    }

    .full {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    ul,
    .logo {
      display: none !important;
    }
  }
}

.sidebar-top {
  position: absolute;
  width: 100%;
  background-color: $color-primary;
  z-index: 1;
}

// --- Ant Design Overrides ---

// Button
.ant-btn,
[type="button"] {
  .anticon {
    display: inline-flex;
    align-items: center;
  }
}
.ant-btn-primary {
  box-shadow: none;
}

// Modal
.ant-modal {
  .ant-modal-header {
    margin-bottom: 1.25rem;
  }
  .ant-modal-title {
    font-size: 1.25rem;
    color: $color-primary;
  }
}

// Form elements
.ant-form-item-control-input-content {
  .ant-select .ant-select-selector {
    border-radius: 0;
  }
  .ant-input {
    border-radius: 0;
  }
}

// General CSS
header {
  .header-mobile {
    display: block;

    &.col-12 {
      padding: 0px;
    }

    &.logo {
      flex: 0;
    }
  }

  .fa-bars {
    display: inline-block;
    font-size: 30px;
    color: $color-primary;
    cursor: pointer;
  }

  .shadow-bottom {
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
  }

  .mobile-menu a,
  .hamburger-menu a {
    color: $color-medium-gray;
  }

  .mobile-menu {
    display: block;
    overflow: auto;
    white-space: nowrap;
    border-bottom: solid 2px $color-light-gray;

    .nav-link {
      display: inline-block;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .hamburger-menu {
    display: block;

    a:hover {
      color: $color-white;
      background-color: $color-primary;
    }
  }
}

.container-fluid {
  max-width: 1600px;
  &.full-width {
    max-width: none;
    padding-right: 30px;
  }
  & .application-header {
    padding: 0 14px 0 16px;
  }
}

.loader-wrapper,
.files-loader-wrapper {
  height: calc(100vh - 77px);
  width: 100%;
  position: absolute;
  top: 77px;
  left: 0px;
  right: 0px;
  z-index: 9998;

  .spinner-border {
    top: 50%;
    opacity: 1;
    left: 50%;
    position: absolute;
    z-index: 9999;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: $color-white;
    height: 300%;
    width: 100%;
    top: 0;
    bottom: 0;
  }
}

.file-upload-wrapper {
  border: solid 1px #dee2e6;

  .row {
    min-height: 300px;
  }
}

.files-loader-wrapper::after {
  background-color: rgba(255, 255, 255, 0.5);
}

.logo {
  text-decoration: none;
  color: $color-primary;

  &:hover {
    text-decoration: none;
    color: $color-primary;
  }

  h3 {
    margin-left: 10px;
  }
}

.dropdown {
  display: none;

  button.fake-link.btn-fake-link {
    height: 35px;
    width: 35px;
    border-radius: 50px;
  }

  .dropdown-menu {
    z-index: 999999;
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: $color-white;
  background-color: $color-secondary;
}

.company-pic {
  flex: 0;
  border: solid 1px #979797;
  background-color: $color-white;
  padding: 0;
}

.bio {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.panel {
  position: relative;
  border: solid 1px $color-primary;
  text-align: center;
  margin-bottom: 20px;

  a,
  span {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: $color-primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  span {
    cursor: pointer;
    line-height: 1.2;
  }

  .panel-controls {
    display: none;
    position: absolute;
    right: 15px;
  }
}

.pagination {
  border-radius: 0;

  .page-item.active .page-link {
    background-color: $color-secondary;
    border-color: $color-secondary;
  }

  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-radius: 0;
  }

  .btn {
    padding: 0 !important;
    width: 32px;
    height: 32px;
    margin-right: 0.25rem;
  }
}

.equity-container {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 2px;
  border-bottom: solid 2px $color-primary;
  height: 38px;
  background-color: #e9ecef;

  div {
    display: inline-block;
    padding-left: 11px;
  }

  label {
    display: block;
  }
}

.my-id {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.carousel-item img {
  margin: 0 auto;
  height: 300px;
  width: auto;
}

.carousel-control-prev,
.carousel-control-next {
  color: $color-primary;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;

  &:hover {
    color: $color-secondary;
  }

  &:focus {
    color: $color-secondary;
  }

  svg {
    position: relative !important;
    right: auto !important;
    top: auto !important;
    font-size: 30px !important;
  }
}

.program-view {
  h4 {
    white-space: pre-wrap;
  }

  // div:not(.row) {
  //   width: 100%;
  //   position: relative;
  // }

  .program-toggle {
    display: block;
    width: 100%;
    background: $color-light-gray;
    border: none;
    padding-left: 5px;
    margin-right: 15px;
    cursor: pointer;

    h4,
    label {
      pointer-events: none;
    }

    svg {
      pointer-events: none;
    }
  }

  .program-collapse {
    border: solid 1px rgba(0, 0, 0, 0.3);
    border-top: solid 2px $color-secondary;
    background: $color-white;
    z-index: 1;
    padding: 5px;
  }

  .is-other {
    white-space: pre-wrap;
    border-top: solid 2px $color-secondary;
  }

  .more-info {
    white-space: pre-wrap;
  }
}

.no-flex {
  flex: 0;
}

.flex-0 {
  text-align: center;
  width: 256px;
  padding: 0;
}

.flex-1 {
  flex: 1;
}

.image-bg {
  display: block;
  height: 256px;
  width: 256px;
  background-size: cover;
  background-repeat: no-repeat;
}

.image-md {
  height: 200px;
  width: 200px;
}

.image-sm {
  height: 120px;
  width: 120px;
}

.modal svg {
  cursor: pointer;
}
.modal-class {
  .modal-content {
    width: 96% !important;
  }
}
.dark-modal {
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    width: 96% !important;
    border: none;
    background: rgba(0, 0, 0, 0.3);
  }

  .modal-header {
    border: none;
    padding-bottom: 0;
    color: $color-white;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.nav-link.active {
  border-bottom: solid 2px $color-primary;
}

.card {
  border: none;
  border-radius: 0;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25);

  h2 {
    line-height: 32px;
  }

  .focusable-header {
    box-sizing: border-box;
    border: solid 2px transparent;
    border-bottom: 2px solid rgba(0, 0, 0, 0.125);
    justify-content: flex-end;
    align-items: center;
    display: flex;

    span:first-of-type {
      flex: 1;
    }
  }

  .card-body {
    position: relative;
    padding: 0;
  }

  .card-footer {
    background-color: $color-light-gray;
  }

  .row.no-gutters {
    height: 100%;

    .col-md-12 {
      border-bottom: solid 1px $color-white;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .card-content {
    padding-right: 165px;
  }

  .deal-details {
    display: block;

    .small-width {
      display: inline-block;
      max-width: 150px;
      width: 100%;
    }
  }

  .deal-details-mobile {
    display: none;
  }

  .deal-actions {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 150px !important;
    padding: 0;
  }

  .deal-action-item {
    display: block;
    color: $color-white;
    background-color: $color-primary;
    text-align: center;
    height: 100%;

    .deal-action-item-wrapper {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
    }

    svg {
      font-size: 20px;
    }

    p {
      width: 100%;
      display: block;
    }
  }
}

.lender-card {
  cursor: pointer;
  transition: margin 200ms ease-in-out;
  z-index: 3;

  .card-body {
    padding: 15px;
  }
}
.lender-card.lender-passed {
  background: $color-light-gray !important;
}
.inner-accordion {
  border-bottom: solid 2px;
  width: 50%;
  cursor: pointer;

  h5,
  svg {
    pointer-events: none;
  }
}

.card-active {
  background-color: rgba(10, 70, 127, 0.2) !important;
}

.inbox-action-item {
  display: inline-block;
  color: #007bff;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bulk-actions {
  display: inline-block;
}

.bulk-actions-mobile {
  display: none;
}

.quick-filters {
  .outline {
    cursor: pointer;
    border: solid 2px $color-primary;
    background-color: $color-white;
    color: $color-primary;
  }

  .selected {
    cursor: pointer;
    border: solid 2px $color-primary;
    background-color: $color-primary;
    color: $color-white;
  }
}

.group-list {
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    flex: 1;
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn {
    margin-top: 20px;
    padding: 0px !important;
  }
}

.inbox-card {
  cursor: pointer;
  transition: margin 200ms ease-in-out;
  z-index: 3;

  .btn-primary {
    padding: 7px 0px;
    width: 100%;
  }

  .card-header,
  .card-body {
    pointer-events: none;

    a,
    .btn,
    .inbox-actions .d-inline-block {
      pointer-events: initial;
    }
  }
}

.inbox-card,
.message-card {
  border: solid 1px transparent;

  textarea {
    resize: none;
    font-size: 14px;
    line-height: 22px !important;
    border: solid 1px rgba(52, 66, 81, 0.5) !important;
    border-bottom: solid 2px rgba(52, 66, 81, 0.5) !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
  }

  textarea::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .badge-pill {
    cursor: pointer;
  }

  .card-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .card-body {
    padding: 15px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .message-container {
    display: flex;
    flex-direction: column-reverse;
    height: 500px;

    &::after {
      content: "";
    }

    .row.no-gutters {
      height: auto;
    }

    img {
      cursor: pointer;
      height: auto;
      width: 100%;
      max-width: 250px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .message-inner-container {
    overflow: auto;
    overflow-y: scroll;
    padding: 15px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .message-from-name {
    display: block;
    color: $color-gray;
    font-size: 12px;
  }

  .message-me .message-from-name {
    margin-right: 11px;
  }

  .message-them .message-from-name {
    margin-left: 11px;
  }

  .message-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .message-initial {
    color: $color-medium-gray;

    p {
      font-size: 12px;
      line-height: 20px;
      display: inline-block;
      padding: 7px;
    }
  }

  .message-them,
  .message-me {
    display: inline-table;
  }

  .message-them {
    p,
    .message-content {
      font-size: 12px;
      line-height: 20px;
      display: inline-block;
      border-left: solid 4px $color-purple;
      padding: 2px 7px;

      span {
        text-align: left;
      }
    }
  }

  .message-me {
    p,
    .message-content {
      font-size: 12px;
      line-height: 20px;
      display: inline-block;
      text-align: left;
      border-right: solid 4px $color-secondary;
      padding: 2px 7px;

      span {
        text-align: right;
      }
    }
  }

  .message-them p,
  .message-me p {
    span {
      display: block;
      margin-top: 2px;
      color: white;
      line-height: 10px;
    }
  }

  .row-me + .row-me,
  .row-them + .row-them {
    margin-top: 15px;
  }

  .row-me + .row-them,
  .row-them + .row-me {
    margin-top: 15px;
  }
}

.inbox-card .inbox-actions,
.message-card .inbox-actions {
  svg {
    cursor: pointer;
    font-size: 20px;

    &:hover {
      color: $color-secondary;
      opacity: 1;
    }
  }
}

.inbox-card .inbox-actions {
  visibility: hidden;
}

.inbox-card:hover .inbox-actions {
  visibility: visible;
}

.messsage-card .inbox-actions {
  display: block;
}

.is-dragging {
  box-shadow: none;
  background: $color-white !important;
  border: dashed 1px $color-medium-gray;

  .inbox-actions {
    visibility: hidden !important;
  }

  .card-header,
  .card-body,
  .card-footer {
    visibility: hidden;
  }
}

.action-wrapper {
  position: relative;

  input[type="checkbox"] {
    pointer-events: none;
    height: auto;
    width: auto;
    z-index: 2;
  }

  input[value="true"] + label {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    z-index: 1;
  }

  .field-wrapper {
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 20px;
    width: 30px;
  }
}

.mobile-merged {
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: $color-white;
}

.mobile-messenger .card-body {
  padding: 0px;
}

.messenger-actions {
  order: -1;
  margin: 0px 15px;
  position: relative;
}

.accordion.merged-questions {
  .card-header {
    color: $color-secondary;
  }

  .card-body {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  svg {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    vertical-align: middle;
    font-size: 14px !important;
  }
}

.mobile-messages {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-primary;
  color: $color-white;
  z-index: 3;
  text-align: center;

  .btn {
    width: 100%;
    padding: 0px;
  }
}

.price-table {
  .price-table-item {
    box-shadow: 6px 6px 4px rgba(134, 123, 123, 0.1);
    border: solid 1px #0a467f;
    border-bottom: solid 27px #0a467f;
    text-align: center;
    padding: 20px;
    .price-table-heading {
      color: #0a467f;
      font-size: 18px;
    }
    h3 {
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .price-table-item-plain {
    text-align: center;
    a {
      color: #0a467f;
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
}

.text-heading {
  color: #0a467f;
}

.accordion {
  .card-header {
    background-color: $color-white;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
  }

  .card-body {
    padding: 15px;
  }

  .description {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.team-card {
  .card-header {
    padding-right: 15px;
    padding-left: 15px;
  }

  .card-body {
    padding: 15px;
  }

  img {
    border-radius: 50px;
  }
}

.manage-company-view-edit {
  white-space: nowrap;
  overflow: hidden;

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.company-card {
  a.d-flex {
    color: $color-medium-gray;
    text-decoration: none;

    .flex-1 {
      overflow: hidden;
    }

    h4,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.company-team-lead-info {
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 30px;

  .company-team-lead-details {
    max-width: 200px;
    width: 100%;
  }

  .col-sm-4 p,
  .col-sm-3 p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// File Manager
.grid-container {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
}

.grid-together {
  margin: 0 15px;
  flex: 0 0 100%;
  max-width: 353px;
  border-top: 2px dotted rgb(117, 57, 114);
}

.file-upload-wrapper .drag-drop-file-upload {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.container-fluid .drag-drop-file-upload {
  display: none;
}

.grid-col-container {
  display: flex;
  position: relative;
  border: 2px solid $color-primary;
  flex-flow: column wrap;
  background: $color-white;
  padding: 6px;
  text-align: center;
  align-items: center;
  width: 160px;
  height: 160px;

  .btn {
    opacity: 1 !important;
  }

  .drag-drop-file-upload {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0;
  }

  &.dark {
    color: $color-white;
    background-color: $color-primary;

    .btn {
      opacity: 1 !important;
    }

    svg {
      color: $color-white;
    }
  }

  &.folder {
    position: relative;
    box-sizing: border-box;
    height: 145px;
    border-radius: 0px 10px 0px 0px;

    &::before {
      content: "";
      position: absolute;
      height: 15px;
      width: 75px;
      top: -15px;
      left: -2px;
      background: $color-primary;
      border-radius: 10px 10px 0px 0px;
    }
  }
}

.mb-30 {
  margin-bottom: 35px;
  height: 160px;
}

.grid-row {
  width: 100%;
  display: flex;
  text-align: start;
}

.grid-item {
  width: 100%;
}

.grid-col-name {
  flex-grow: 1;
}

.grid-file-name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.view-as {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex:focus-within {
  .dropdown {
    visibility: visible;
  }
}

.folder-name,
.file-name {
  flex: 1;
  cursor: pointer;
  padding: 5px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-medium-gray;

  &:hover + .folder-file-dropdown .btn {
    opacity: 1 !important;
  }
}

.grid-container .folder-file-dropdown {
  z-index: 3;
}

.file-item-row:hover .folder-file-dropdown .btn,
.file-item-row:hover .d-flex.btn-folder {
  opacity: 1;
}

.folder-file-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-file-dropdown .btn:not(.btn-folder-master),
.file-item-row .d-flex.btn-folder {
  opacity: 0;

  &:focus,
  &:hover {
    opacity: 1;
  }
}

.btn-folder-master {
  opacity: 1;

  svg {
    width: 18px !important;
    height: 18px;
  }
}

.hover-cursor {
  cursor: pointer;
}

.file-item-header {
  font-size: small;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
}

.grid-file-item-header {
  font-size: small;
  font-weight: bold;
}

.file-item-row {
  padding: 14px 0px;
  border-bottom: 1px solid #cccccc;

  .col-5,
  .col-8 {
    height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    color: $color-primary;
    width: 18px !important;
    height: 18px !important;
  }
}

.is-selected-file {
  color: $color-white;
  background-color: $color-primary;

  svg {
    color: $color-white;
  }
}

.team-link-item {
  &:focus,
  &:hover {
    color: $color-white;

    .link-text {
      color: $color-white;
    }
  }
}

// Media Queries
@media (max-width: 991px) {
  .fab {
    display: block;
  }

  .inbox-row {
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .message-card,
  .inbox-card .inbox-actions {
    display: block;
  }

  #messenger {
    display: flex;
  }

  .messenger-col {
    position: relative !important;
  }
}

@media (max-width: 900px) {
  .header-spacer {
    display: none;
  }

  .search-form-not-mobile {
    display: none !important;
  }

  .search-form-mobile {
    max-width: 100% !important;
    margin-right: 0 !important;
  }

  .loader-wrapper,
  .files-loader-wrapper {
    height: calc(100vh - 107px);
    top: 107px;
  }

  .merged-questions {
    display: none;
  }

  .dashboard-select-container {
    display: block;

    p {
      display: block;
    }

    .dashboard-select {
      display: inline-block;
      margin: 10px 0px;
    }
  }

  .quick-filters {
    .filter-section {
      display: none;
    }
    .filter-badges {
      margin-top: 10px;
    }
  }
  .mobile-messenger {
    position: fixed !important;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    padding: 0px;

    #messenger {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .message-container {
      overflow: auto;
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
    }
  }
}
.sticky-top {
  position: sticky !important;
}
@media (min-width: 1600px) {
  .container-fluid {
    margin-left: 0px;
  }
}
@media (min-width: 900px) {
  header {
    border-bottom: solid 2px $color-light-gray;

    .header-mobile {
      display: none;
    }

    .dropdown {
      display: inline-block;
    }

    .fa-bars {
      display: none !important;
    }
  }

  aside#sidebar,
  .sidebar-col {
    display: block;
  }

  .flex-0 {
    flex: 0;
  }

  .tab-content {
    max-width: calc(100vw - 330px);
    min-height: 400px;
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .card:not(.company-card) {
    h2 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    .deal-details {
      display: none;
    }

    .deal-details-mobile {
      display: block;
    }

    .card-content {
      padding-right: 15px;

      .row:first-of-type {
        flex-direction: row-reverse;
      }
    }

    .deal-actions {
      position: relative;
      padding: 0 15px;
      padding-top: 20px;

      .deal-action-item p {
        font-size: 10px;
        line-height: 10px;
      }

      .row {
        .col-md-12 {
          font-size: 14px;
          font-weight: 700;
          border-bottom: none;
          border-right: solid 1px $color-white;

          &:last-of-type {
            border-right: none;
          }
        }

        .action-hidden-mobile {
          display: none;
        }
      }
    }

    .deal-action-item .deal-action-item-wrapper {
      position: relative;
      transform: none;
      top: initial;
      padding: 10px 0;
    }
  }

  .message-card,
  .inbox-card {
    .message-send svg {
      margin-right: 0px;
      margin-top: 2px;
      font-size: 20px;
    }

    span {
      display: inline;
    }
  }

  .inbox-row {
    margin-bottom: 70px;
  }

  .mobile-messages {
    display: block;
    span {
      font-size: 12px;
    }
  }

  .team-card {
    img {
      width: 50px;
      height: auto;
    }
  }

  .inner-accordion {
    width: 100%;
  }

  .bulk-actions {
    display: none;
  }

  .bulk-actions-mobile {
    display: block;
  }

  .inbox-actions {
    visibility: visible !important;
  }

  .btn-paginate {
    width: 44px !important;
    height: 44px !important;
  }

  .mobile-no-padding {
    padding: 30px 0px;
  }

  .company-card img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 575px) {
  .mobile-img {
    border-radius: 50px;
  }

  .application-controls {
    a,
    button {
      width: 100%;
    }
  }
}

@media print {
  header,
  .sidebar-col,
  .previewActions,
  #hubspot-messages-iframe-container.widget-align-right {
    display: none !important;
  }

  @page {
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-top: 0.25in;
    margin-bottom: 0.25in;
  }
}

.section-hr {
  border: 0.5px solid #696767;
  margin-top: 0rem;
}

.quote-card {
  width: 220px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 5px;
  border: 1px solid grey;
  &.add-quote {
    border: none;
    & .quote-card-body {
      z-index: -1;
    }
  }
  & .click-container {
    padding-bottom: 75%;
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    & .plus-button {
      padding: 0;
      width: 76px;
      height: 76px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}
.quote-card-body {
  .row {
    border-bottom: 1px solid lightgray !important;
    height: 60px;
    padding-top: 10px;
    align-items: center;
  }
  & form .icon-input svg {
    z-index: 0;
    top: 16px;
  }
}

.quote-labels-body {
  .row {
    height: 60px;
    align-items: center;
  }
  &.readOnly .row {
    height: 60px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
  }
}

.quote-create-card {
  width: 220px;
  margin-top: 5px;
  .select__control {
    border-radius: 0;
    .select__value-container {
      padding-left: 0;
    }
    &.select__control--is-disabled {
      background-color: #e9ecef;
      .select__single-value,
      .select__placeholder,
      svg {
        color: #495057;
      }
    }
  }
}
.quote-create-card {
  .field-wrapper {
    border-bottom: 1px solid lightgray !important;
    height: 60px;
    padding-top: 10px;
  }
}

.deal-quote-row {
  border-bottom: 1px solid #e0dede;
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.archive {
    background-color: #f9f6f6;
    opacity: 0.5;
  }
}

.tertiary-link {
  color: $color-purple;
  cursor: pointer;
}

.focus-quote {
  box-shadow: 0px 0px 4px 4px $color-secondary;
}
.focus-msg {
  box-shadow: 0px 0px 4px 4px $color-secondary;
  padding: 10px;
}
// .focus-quote {
//   animation: fade-shadow 10s forwards;
// }

// @keyframes fade-shadow {
//   from {
//     box-shadow: 0px 0px 4px 4px $color-secondary;
//   }
//   to {
//     box-shadow: none;
//   }
// }

.quote-actions-dropdown {
  right: -25px;
  position: absolute;
  top: -5px;
}

pre {
  white-space: break-spaces;
  font-family: inherit;
}

.wygiwys {
  ol,
  ul {
    list-style: revert !important;
    line-height: initial !important;
    margin-top: 0;
    margin-bottom: 0px;
    padding-left: 20px;
  }
}

.multiDropdown-wrapper {
  position: relative;
}

.multiDropdown-container {
  z-index: 1;
  position: absolute;
  top: 36px;
  right: 0;
  width: 20vw;

  &.left {
    left: 0 !important;
  }

  .react-select-container_inbox-messenger {
    width: 100%;

    .control-container {
      padding: 12px 16px;
      background: white;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border: 1px solid rgba(52, 66, 81, 0.5);
      border-bottom: 0;
    }

    .react-select__control {
      min-height: 0;
      border-radius: 0;
      border-color: #4683d1;
    }

    .react-select__value-container {
      padding: 0;

      & > div {
        margin: 0 2px;
        padding: 0 2px;
      }
    }

    .react-select__indicator-separator {
      display: none;
    }

    .icon-container {
      padding: 0 8px;
      font-size: 12px;
    }

    .horizontal-separator {
      width: 100%;
      height: 1px;
      margin-top: 12px;
      background: hsl(0, 0%, 80%);
    }

    .react-select__menu {
      margin-top: -8px;
      padding: 0;
      z-index: -1;
      box-shadow: none;
      border: 1px solid rgba(52, 66, 81, 0.5);
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .react-select__option {
      padding: 8px 16px;
      background: white;
      color: #212529;
      cursor: pointer;

      & input,
      label {
        cursor: pointer;
      }

      .form-check-input {
        width: auto;
        height: auto;
        position: absolute;
        margin-top: 0.3rem;
        margin-left: -1.25rem;
      }

      .form-check-label {
        position: unset;
      }
    }

    .apply-container {
      background: #efefef;
      padding: 6px 16px;
    }
  }
}

.chips-container {
  width: 100%;
  max-height: 146px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  .badge-primary {
    cursor: auto;
    background: #b986bb;
    font-weight: normal;
    margin-left: 0 !important;
  }
}

.switch-lg.custom-switch {
  padding-bottom: 0.25rem;

  .custom-control-input {
    width: 100%;
  }

  .custom-control-label {
    &::before {
      width: 2.25rem;
      height: 1.25rem;
      border-radius: 1rem;
    }
    &::after {
      width: calc(1.5rem - 4px);
      height: calc(1.25rem - 4px);
    }
  }
}

.droptarget {
  &-active {
    outline: 1px solid $color-primary;
    background-color: lighten($color: $color-primary, $amount: 60%);

    &.error {
      outline: 1px solid $color-error;
      background-color: lighten($color: $color-error, $amount: 40%);
    }
  }
  &-info {
    & svg {
      color: $color-primary;
    }
    & .detail {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.login-as-item {
  font-size: 12px;
  word-wrap: break-word;
  border-bottom: 1px solid #bbb7b7;
  white-space: initial;
}

// Google places auto suggestion dropdown
.pac-container {
  z-index: 1500;
}

.form-wizard-wrapper {
  & .loader-wrapper {
    opacity: 0.75;
    height: 100%;
    top: 0;

    &::after {
      height: 100%;
    }
  }
}

// timeline
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 10px;
  width: 2px;
  height: 100%;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 30px;
}
ul.timeline > li:first-child {
  margin-top: 0;
}
ul.timeline > li:before {
  content: " ";
  background: $color-white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid $color-primary;
  left: 0;
  width: 20px;
  height: 20px;
}
ul.timeline > li .badge {
  background: $color-primary;
  color: $color-white;
}

.react-select-container_tags-dropdown {
  .react-select__control {
    border: 1px solid #ced4da;
    min-height: 33px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__single-value {
    color: #495057;
  }
}

.highlighted {
  background-color: yellow;
}

.SplitPane {
  top: unset !important;
  bottom: unset !important;

  &.QuoteMatrix {
    .Pane1 {
      overflow: auto;
    }
    .Pane2 {
      overflow: auto;
    }
    &.hidePane2 {
      .Resizer,
      .Pane2 {
        display: none;
      }
    }
  }

  &.SideBar {
    .Resizer {
      display: none;
      background: rgba(255, 255, 255, 0.25) !important;
      &::before {
        background-image: url("/images/vector-arrow-white.svg");
      }
    }
    .Pane1 {
      overflow-y: auto;
      max-height: 100vh;
    }
    .Pane2 {
      display: none;
      background-color: #0a467f;
      .SearchInput {
        padding: 0.375rem 0.75rem;
        color: #fff;
        background: #03325f;
        border: 1px solid #c4c4c4;
        border-radius: 0;
      }
      .SidebarSelectSections {
        @extend .sidebar-scrollbar;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - (220px + 64px + 64px + 64px));
        padding-right: 24px;
        .rowGroup {
          .title {
            border-bottom: 1px solid #c4c4c4 !important;
          }
        }
        .rowItem {
          border-bottom: 1px solid #c4c4c4;
          .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .icon {
            color: #c4c4c4;
          }
        }
      }
    }
  }

  .Resizer {
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;

    opacity: 1 !important;
    height: 1px !important;
    background: rgba(0, 0, 0, 0.25) !important;
    border-top: 0 solid #000 !important;
    border-bottom: 0 solid #000 !important;

    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      background-image: url("/images/vector-arrow-blue.svg");
      display: block;
      content: " ";
      background-size: 16px 16px;
      height: 16px;
      width: 16px;
    }
    &:hover {
      background: rgba(0, 0, 0, 1) !important;
    }
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: 12px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
}

.create-from-deal {
  .list-group {
    min-height: 240px;
    max-height: 240px;
    overflow-y: scroll;
    .list-group-item {
      padding: 0.25rem 1.25rem;
      border: 0;
      border-radius: 0;
      cursor: pointer;
    }
  }

  .table-responsive-sm {
    min-height: 240px;
    max-height: 240px;
    overflow-y: scroll;

    tr > th {
      border-top: 0;
      border-bottom: 0;
    }
    tbody > tr {
      cursor: pointer;
    }
    .row-item-primary {
      color: #004085;
      background-color: #b8daff;
    }
  }
}
.link-sec-action {
  font-size: 12px;
  color: #999;
}
@media (max-width: 768px) {
  .notifications-layout {
    &.popover {
      min-width: 300px;
    }
  }
}
// notiifications
.notifications-layout {
  font-size: 14px;
  .notification-card {
    margin-right: 5px;
  }
  .active-tab {
    &.fake-link.btn-fake-link {
      color: #007bff;
    }
  }
  &.popover {
    max-width: 60%;
  }
  .tab {
    &.fake-link.btn-fake-link {
      color: #212529;
      &:focus {
        color: #007bff;
      }
      &:hover {
        color: #007bff;
      }
    }
  }
}
.notification-badge {
  &.badge-pill {
    margin-left: -4px;
  }
  margin-left: -15px;
  margin-bottom: 5px;
}
.notification-section-title {
  color: #7a7a7a;
  margin: 20px 5px;
}
.no-notifications {
  text-align: center;
  width: 400px;
}
.notification-date-label {
  font-weight: 500;
  color: rgb(153, 153, 153);
}
.inbox-notification {
  margin: 5px;
  border: 1px solid #e6e6e6;
  word-break: break-word;
  border-radius: 5px;
  /* margin: 5px 5px 10px; */
  padding: 2px 10px;
  .reply-header {
    font-weight: 600;
    margin-bottom: 2px;
  }
}
.notification-section {
  width: 100%;
  .notification-file-icon {
    font-size: 16px;
    cursor: pointer;
  }
}

.nav-tabs {
  border: none;
  .nav-item {
    margin-bottom: 0;
    color: inherit;
    border: 1px solid #dee2e6;
    border-left: none;
    &:first-child {
      border-left: 1px solid #dee2e6;
    }
  }
  .nav-link {
    border-radius: 0;
    &.active {
      color: #fff;
      background-color: #0a467f;
    }
  }
}

.tab-content .tab-pane {
  padding: 0.5rem 0;
}
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.08);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
// email preferences
.custom-email-preference {
  margin: 10px 0;
  .btn {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .non-selected-btn {
    color: #007bff;
    background: white;
    border: 2px solid #007bff;
  }
  .selected-btn {
    background: #007bff;
  }
}
.custom-a-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.read-or-hide {
  color: #007bff;
  cursor: pointer;
}
.app-layout-content {
  padding: 0;
}
.app-main-layout {
  padding-left: 1rem;
  padding-right: 1rem;
}
.app-header-navbar {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  background: white;
}
.app-header {
  width: 100%;
}
.row-selected {
  color: $color-white !important;
  background-color: $color-primary !important;
}

.pointer-events-none {
  pointer-events: none;
}
.disable-action {
  cursor: not-allowed;
  pointer-events: none;
}
.disable-action.deal-card {
  background-color: #cccccc !important;
}

.disable-action.deal-link {
  color: #cccccc !important;
}

.table-termsheet-preview {
  .td-table-matrix-wrapper {
    position: relative;
  }
  .table-matrix-wrapper {
    /*this will be overriden in JS*/
    max-width: 600px;
    overflow-x: auto;
    margin-left: 25%;
    padding: 0;

    table {
      border-collapse: separate;
      border-spacing: 0;
      border-top: 1px solid lightgray;
    }

    td,
    th {
      margin: 0;
      padding: 4px 8px;
      // border-top-width: 0px;
    }

    .headcol {
      position: absolute;
      width: 25%;
      left: 0;
      top: auto;
      margin-top: -1px;
      padding-left: 0;
    }

    .cell {
      border: 1px solid lightgray;
      min-width: 300px;
      height: 38px;
    }
  }
}

.CompareQuotes {
  .list-group-flush {
    border: 1px solid #000000;
    box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
    &.headers {
      border: none;
      box-shadow: none;
      .list-group-item {
        border-bottom-width: 0;
      }
    }
    .list-group-item {
      min-height: 48px;
      // max-height: 48px;
    }
  }
  .column-item-container {
    min-width: 200px;
    max-width: 200px;
    margin-right: 1rem;
  }
}

.AddClauseCompare {
  min-height: 50vh;
  max-height: 50vh;
  overflow: auto;
  .rowItem {
    &:hover {
      background-color: #007bff40;
    }
  }
  .col-auto {
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
}
.welcome-user-email {
  border: 1px solid gray;
  padding: 5px;
  border-radius: 25px;
}
.welcome-user-change {
  color: $color-primary;
  font-weight: 600;
  cursor: pointer;
}
.welcome-error-link {
  color: $color-primary;
  cursor: pointer;
  text-decoration: underline;
  color: #cccccc !important;
}
.text-overflow-msg {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

// dashboard mobile view fixes
.dashboard-page {
  .deal-card {
    .deal-title {
      line-height: 1.2;
    }
  }
}
.add-deal-info-card {
  .btn {
    padding: 6px 30px !important;
  }
}
// header mobile view fixes
@media (max-width: 575px) {
  .application-header {
    h3 {
      font-size: 22px;
    }
  }
}
.termsheet-choice {
  margin-bottom: 1rem;
}
// quote summary section
.quote-summary-section {
  .quote-summary-edit-termsheet {
    display: none;
  }
}
// empty dashboard
.empty-dashboard {
  margin-top: 15px;
  font-size: 14px;
  ul {
    display: block;
    margin-left: -10px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  ul li {
    margin-left: 5rem;
    display: block;
    position: relative;
  }

  // ul li:not(:last-child) {
  //   margin-bottom: 16px;
  // }

  ul li:before {
    content: "";
    position: absolute;
    top: 1.2em;
    left: -25px;
    margin-top: -0.9em;
    background: #97b3ec;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  .image-section {
    display: flex;
    justify-content: center;
  }
  .emtpy-dashboard-img {
    background-image: url("../../src/images/Frame-10.png");
    width: 350px;
    height: 250px;
    margin: 15px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .emtpy-dealcreation-img {
    background-image: url("../../src/images/dealcreation.png");
    width: 220px;
    height: 150px;
    margin: 15px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .gap-20 {
    gap: 20px;
  }
}

.emtpy-inbox-img {
  background-image: url("../../src/images/empty-inbox.png");
  width: 350px;
  height: 250px;
  background-size: contain;
}
.noresults-img {
  background-image: url("../../src/images/emptyresults2.jpg");
  width: 350px;
  margin: auto;
  height: 250px;
  background-size: contain;
}
.empty-inbox-screen {
  margin-top: 25px;
  margin-bottom: 20px;
  .header-section {
    text-align: center;
  }
  .image-section {
    display: flex;
    justify-content: center;
  }
}

.no-results-screen {
  .no-result-text {
    text-align: center;
    h3 {
      margin-top: 2rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
}
// company profile - users section
.company-users-list {
  padding-bottom: 1rem;
  .label-text {
    font-weight: bold;
    padding-bottom: 0.2rem;
  }
}

#dealPdfIFrameContainer iframe {
  width: 100%;
  height: 100vh;
}

.droppable-container {
  border: 1px solid transparent;
  .droppable-placeholder {
    position: absolute;
    margin-top: 10px;
    border: dashed 1px $color-secondary;
    background-color: lighten($color: $color-secondary, $amount: 50);
  }
}
.is-dragging--CLAUSES,
.is-dragging--DEAL_CARDS {
  .droppable-container .droppable-content {
    border: dashed 1px $color-secondary;
    > * {
      opacity: 0.25;
    }
  }
}

.label-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InboxLayout {
  .custom-control-input {
    z-index: 1;
  }
  .badge-primary {
    color: $color-primary;
    background: rgba(41, 127, 209, 0.1);
    font-weight: normal;
    border-radius: 0;
  }
}
.InboxMessages {
  .InboxMessages--item {
    padding-top: 20px;
    padding-bottom: 20px;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $color-light-gray;
    }
  }
}
.portifolio-deals {
  // .ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
  //   background: red !important;
  // }
  // .ant-tabs-card >.ant-tabs-tab.ant-tabs-tab-active{
  //   background: blue !important;
  // }
  // .ant-tabs-tab .ant-tabs-tab-active {
  //   background: red !important;
  // }
}
// Powered by Tiny
.tox-statusbar__branding {
  display: none;
}
.property-manager {
  display: flex;
  gap: 45%;
}